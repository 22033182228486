.Header {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 53px;
    left: 0px;
    top: 0px;
    background: #FFFFFF;
    border-bottom: 1px solid #E8E8E8;
}

.Header .brand {
    position: absolute;
    width: 87px;
    height: 31px;
    left: 11px;
    top: 11px;
}

.Header .right {
    box-sizing: border-box;
    position: absolute;
    width: 177px;
    height: 53px;
    left: 1263px;
    top: 0px;
    border-left: 1px solid #E8E8E8;
} 

.Header .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    position: absolute;
    width: 141px;
    height: 17px;
    left: 18px;
    top: 18px;
} 


.Header .user {
    box-sizing: border-box;
    position: absolute;
    width: 192px;
    height: 53px;
    right: 0;
    top: 0px;
    border-left: 1px solid #E8E8E8;
} 

.Header .profilePic {
    width: 16px;
    height: 16px;
    border-radius: 50%;
}

.Header .logout {
    box-sizing: border-box;
    position: absolute;
    color: #8C1515;
    left: 800px;
    top: 0px;
} 

.div1 {
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-start;
    /* padding: 24px; */
    gap: 10px;
    background: #FFFFFF;
    border-right: 1px solid #E8E8E8;
}

.div1row1 {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    padding: 0px;
    gap: 16px;
    /* width: 344px;
    height: 41px;
    flex: none;
    order: 0;
    flex-grow: 0; */
}

.activityTypeCtitle {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #111111;
}

.activityTypeCinfo {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #2D3648;
}

.prompterCardInfo {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #2D3648;
}

.div1row2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.activitydiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    /* width: 344px;
    height: 164px; */
}

.groupdiv {
    /* display: flex; */
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;
    width: 344px;
    height: 36px;
    border-radius: 6px;
}

.activity {
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    padding: 8px;
    gap: 8px;
    border-radius: 10px;
}

.lock2 {
    width: 20px;
    height: 20px;
}

.activityname {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
    word-break: break-word;
}

.createactivitybox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 0px 16px;
    gap: 10px;
    /* position: absolute; */
    /* width: 554px; */
    /* height: 470px; */
    background: #FFFFFF;
    border-radius: 6px;
}

.createactivityboxcontent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 16px;
    /* width: 554px; */
    /* height: 723px; */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.createactivityboxheader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 10px;
    /* width: 554px; */
    height: 41px;
    border-bottom: 1px solid #F3F3F3;
    border-radius: 6px 6px 0px 0px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.createactivityboxheadercontent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* position: absolute; */
    right: 0;
    left: 0;
    /* gap: 351px;
    width: 522px; */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.createactivityhlabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    /* position: absolute;
    left: 10px; */
    color: #8A8A8A;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.createactivityhclose {
    width: 16px;
    height: 16px;
    flex: none;
    /* position: absolute;
    right: 10px; */
    order: 1;
    flex-grow: 0;
}

.mcreateactivityname {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    height: 65px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.mcreateactivitylabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.mcreateactivityinput {
    padding: 8px 16px;
    gap: 10px;
    /* width: 522px; */
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #343434;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.mcreateactivitydescription {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    height: 85px;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.mcreateactivitydescriptionlabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.mcreateactivitydescinput {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 10px;
    height: 56px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #343434;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.mactivitytype {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding: 0px; */
    gap: 16px;
    /* width: 522px;
    height: 159px; */
    flex: none;
    order: 3;
    flex-grow: 0;
}

.mactivitytypelabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.radioone {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* padding: 0px; */
    gap: 8px;
    /* width: 522px; */
    /* height: 45px; */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.radiobtn {
    width: 16px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    background: #8C1515;
}

.radiobtndiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding: 0px; */
    gap: 8px;
    /* width: 434px;
    height: 45px; */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.radioheader {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.radiobody {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    /* line-height: 20px; */
    color: #8A8A8A;
}

.customization {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    padding-top: 5px;
}

.createactbtn {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 10px;
    width: 117px;
    height: 33px;
    background: #8C1515;
    border-radius: 26px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #FFFFFF;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.newQuizHeader {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #2D3648;
}

.blurLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #2D3648;
}

.newQuizSubHeader {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #2D3648;
}

.newQuizSmall {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
}

.newQuizInput {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
}

.activityReportSmallLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #2D3648;
}

.activityReportBigLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #2D3648;
}

.responseReviewLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #2D3648;
}

.voterLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #2D3648;
    opacity: 0.5;
}

.voterAuthorsLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #2D3648;
}

.activityReportRateLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #2D3648;
}

.yourAnswerLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.quizButtonLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.responseAuthor {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #2D3648;
}

.responseContent {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #2D3648;
}

.mobChoiceContent {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #2D3648;
}

.prompterBubblesText {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #2D3648;
}

.prompterWordsLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #2D3648;
}

.viewCommentLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #2D3648;
}

.activeExamModeLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
}

.deletedCommentLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #7C8494;
}

.activitytype {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #111111;
    align-items: flex-start;
}

.activitytypen {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
}

.activitycreate {
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;
    width: 344px;
    height: 32px;
    border-radius: 6px;
}

.settingsIcon {
    width: 20px;
    height: 20px;
}

.settingsLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
}

.div1desc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
}

.graphLabels {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #2D3648;
}

.choiceOptionLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #2D3648;
    opacity: 0.5;
}

.reportOwnerLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #2D3648;
}

.reportOwnerSocialsLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #68758F;
}

.reportActivityHeadings {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #2D3648;
}

.imagediv1 {
    width: 40px;
    height: 40px;
    border-radius: 3.808px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.owner {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #586CD7;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.grouptitle {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #343434;
}

.grouptitlep {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #343434;
    word-break: break-word;
}

.div2 {
    box-sizing: border-box;
    /* display: flex; */
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 10px;
    /* position: absolute; */
    /* width: 325px; */
    /* height: 767px; */
    /* left: 392px; */
    top: 133px;
    background: #FFFFFF;
    border-right: 1px solid #E8E8E8;
}


.div2ActiveBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    gap: 8px;
    /* width: 277px; */
    background: #F8F8F8;
    border-radius: 6px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.div2ActiveBody2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    gap: 8px;
    /* width: 277px; */
    border-radius: 6px;
    flex: none;
    order: 0;
    flex-grow: 0;
}


.imagerow {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    /* width: 67px; */
    height: 16px;
}

.otherimagerow {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 94px;
    height: 16px;
}

.otherQuestions {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #8A8A8A;
}

.question {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
}

.imagequestion {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.questionOwner {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #343434;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.questionReviews {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.05em;
    color: #343434;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.div2Body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    gap: 8px;
    width: 277px;
    height: 57px;
    border-radius: 6px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.otherQuestionImage {
    width: 16px;
    height: 16px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.otherQuestionOwner {
    width: 70px;
    height: 13px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #8A8A8A;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.div2DistinctBody {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.plus {
    width: 16px;
    height: 16px;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: #8C1515;
}

.div2Header {
    box-sizing: border-box;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    background: #FFFFFF;
    border-bottom: 1px solid #E8E8E8;
}

.groupNavInfo {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
}

.activityNameLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    word-break: break-word;
}

.noActivity {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.activitiesLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
}

.div2OverallHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
}

.div2Headersection1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
}

.lock {
    width: 20px;
    height: 20px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.Tab1 {
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.Tab2active {
    color: #8C1515;
}

.Tab2 {
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
}

.Tab2Content {
    gap: 16px;
}

.Tab2 p {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.socketactivity {
    /* display: flex; */
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 17px;
}

.studentconnected {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #1F8956;
}

.studenttyping {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #263FC4;
}

.answerBox {
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    gap: 16px;
    position: relative;
}

.answerheader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 16px;
    /* width: 500px; */
}

.answerprofile {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    height: 16px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.youranswer {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 12px;
    /* width: 500px; */
    /* height: 145px; */
}

.qchoicesdiv {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.create {
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    position: relative;
}

.imagequestion1 {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.questionAuthor {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #2D3648;
}

.questionContentq {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    /* color: #2D3648; */
}

.activityNames {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #68758F;
}

.creatediv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    width: 663px;
    height: 65px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.createlabel {
    height: 17px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.mpHeading {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #343434;
}

.mpcreatediv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5px;
    padding: 0px;
    gap: 12px;
    /* width: 663px; */
    /* height: 65px; */
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
}

.mpcreateinput {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 10px;
    /* width: 600px; */
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
}

.mpanswerdiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    padding: 0px;
    gap: 24px;
    /* width: 663px; */
    /* height: 311px; */
}

.mpanswerheader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    width: 229px;
    height: 58px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
}

.mpchoice {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    width: 229px;
    height: 29px;
    background: #F8F8F8;
    border-radius: 25px;
}

.mpchoicecontent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
}

.mpchoicebtn {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px 16px;
    gap: 10px;
    /* width: 124px;
    height: 29px; */
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.56) 100%);
    box-shadow: 0px 2px 3px rgba(216, 216, 216, 0.6);
    border-radius: 25px;
}

.mpopenbtn {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px 16px;
    gap: 10px;
    /* width: 105px;
    height: 29px; */
    border-radius: 25px;
}

.mpchoicesdiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    /* width: 600px; */
    /* height: 172px;
    flex: none;
    order: 1;
    flex-grow: 0; */
}

.mpchoicediv {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    /* width: 600px; */
    height: 36px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.mpchoiceA {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
}

.mpchoiceinput {
    box-sizing: border-box;
    /* display: flex; */
    /* flex-direction: row;
    align-items: flex-start; */
    padding: 8px 16px;
    gap: 10px;
    /* width: 530px; */
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
}

.icon5 {
    width: 16px;
    height: 16px;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.mpaddchoice {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 106px;
    height: 16px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #8C1515;
    /* flex: none;
    order: 3;
    flex-grow: 0; */
}

.mpaddchoice .icon5 {
    flex: none;
    order: 0;
    flex-grow: 0;
}

.mpsubmitbtn {
    padding: 8px 16px;
    gap: 10px;
    max-width: 130px;
    background: #8C1515;
    border-radius: 26px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #FFFFFF;
}

.createquestion {
    width: 205px;
    height: 26px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #343434;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.image3 {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.owner3 {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #343434;
}

.answerQuestion {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #343434;
}

.answerImage {
    max-height: 80px;
}

.yanswer {
    width: 76px;
    height: 17px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.answerbody {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 10px;
    /* max-width: 500px; */
    /* width: 400px; */
    height: 116px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #343434;
}

.answersubmit {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 10px;
    /* width: 121px; */
    height: 33px;
    background: #8C1515;
    border-radius: 26px;
    flex: none;
    order: 3;
    flex-grow: 0;
}

.submittext {
    height: 17px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.answerList {
    box-sizing: border-box;
    /* display: flex; */
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 10px;
    position: absolute;
    /* width: 600px; */
}

.answerListQuestion {
    /* position: absolute; */
    left: 30px;
    top: 30px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #343434;
}

.answerListBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    gap: 16px;
    /* position: absolute; */
    /* width: 550px; */
    left: 30px;
    top: 72px;
}

.answerListItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    /* width: 550px; */
}

.answerownerdiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
}

.answerownerimage {
    width: 16px;
    height: 16px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.answerowner {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #343434;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.answeritembody {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #343434;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.settingsidebar {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px;
    gap: 10px;
    position: absolute;
    width: 167px;
    left: 392px;
    top: 133px;
    border-right: 1px solid #E8E8E8;
}

.sidebarheadings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 119px;
    flex: none;
    order: 0;
    flex-grow: 1;
}

.sidebarheading {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #8C1515;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.settingsbody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.settingsactivityname {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    position: relative;
    word-break: break-word;
    height: 65px;
}

.sactivityname {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
}

.sinputactivity {
    /* box-sizing: border-box;
    display: flex; */
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 10px;
    /* width: 100%; */
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #343434;
}

.sactivitydesc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    /* width: 833px; */
}

.sactivitydesclabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
}

.sactivitydescinput {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 10px;
    /* width: 700px; */
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #343434;
}

.activeSetting {
    color: #8C1515;
}

.sactivitytype {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
}

.sactivitytypelabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #343434;
}

.settingsradio {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
}

.sradioone {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
}

.sradiobtn {
    width: 16px;
    height: 45px;
    background: #8C1515;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.sradiobtndiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
}

.sradioheader {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
}

.sradiobody {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #8A8A8A;
}

.gssettings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    /* height: 440px; */
    left: 416px;
}

.gsimagediv {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding: 0px; */
    gap: 12px;
    width: 200px;
    /* height: 40px; */
    /* flex: none;
    order: 0;
    flex-grow: 0; */
}

.gschangeimage {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 6px;
    width: 148px;
    height: 17px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.gschangeimage input[type=file] {
    opacity:0;
    width: 148px;
    height: 17px;
    border-radius: 10.4583px;
    position: absolute;
}

.gsplus {
    width: 16px;
    height: 16px;
    color: #8C1515;
}

.gsimagelabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.gsgroupname {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    /* width: 900px; */
    height: 93px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.gsgroupnamelabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
}

.gsinputgroup {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 10px;
    /* width: 900px; */
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #343434;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.gsprivatediv {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 5px;
    width: 93px;
    height: 16px;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.gscheckbox {
    width: 12px;
    height: 12px;
    background: #8C1515;
    color: #8C1515;
}

.privatelabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #343434;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.gsgroupdescdiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    /* width: 1000px; */
    height: 85px;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.gsgroupdesclabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.gsinputgroupdesc {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 10px;
    /* width: 900px; */
    height: 56px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #343434;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.gsgrouppasscodediv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    flex: none;
    order: 3;
    flex-grow: 0;
}

.gspasscodelabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.gsinputgrouppasscode {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 10px;
    /* width: 900px; */
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #343434;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.gssubmitbtndiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 231px;
    height: 33px;
    flex: none;
    order: 4;
    flex-grow: 0;
}

.gssavebtn {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 10px;
    background: #8C1515;
    border-radius: 26px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.gsdeletebtn {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 10px;
    width: 110px;
    height: 33px;
    background: #F8F8F8;
    border-radius: 26px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.gsnav {
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-start;
    /* padding: 0 24px; */
    gap: 10px;
    background: #FFFFFF;
}

.gsnav2 {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 24px;
    gap: 10px;
    height: 80px;
    background: #FFFFFF;
    border-bottom: 1px solid #E8E8E8;
}

.gsnavcontent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 495px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.gsnavcontent2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 495px;
    /* width: 900px; */
    height: 20px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.gsnavcontentleft2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    /* width: 372px;
    height: 20px; */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.gsnavcontentleftgrp2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    /* width: 159px; */
    height: 20px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.gsnavlist2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    /* width: 197px;
    height: 17px; */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.gsnavlistactive2 {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #8C1515;
}

.gsnavlistinactive2 {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #8A8A8A;
}


.gsnavcontentleft {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    /* width: 372px;
    height: 20px; */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.gsnavcontentleftgrp {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    /* width: 159px; */
    height: 20px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.gsnavlabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #343434;
}

.gsnavlist {
    display: flex;
    /* flex-direction: row; */
    align-items: flex-start;
    /* padding: 0px; */
    /* gap: 16px; */
    /* width: 197px;
    height: 17px; */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.gsnavlistactive {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #8C1515;
}

.gsnavlistitem {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #2D3648;
}

.gsnavlistinactive {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #8A8A8A;
}

.research {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 24px;
    width: 100%;
    height: 28px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.researchinput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 6px 14px 6px 14px;
    gap: 10px;
    width: 228px;
    /* height: 28px; */
    background: #F8F8F8;
    border-radius: 21px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.researchright {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;
    width: 88px;
    height: 17px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #8C1515;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.memberrow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 0px;
    width: 100%;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.memberrowcontent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    /* padding: 15px 0px; */
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
    border-bottom: 1px solid #E8E8E8;
    width: 100%;
}

.membername {
    font-weight: 500;
}

.memberrole {
    font-weight: 400;
}

.rediv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 100%;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.redivrow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    /* width: 100%; */
    height: 33px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.redivcontent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    /* gap: 462px; */
    /* width: 100%; */
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
}

.redivleft {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    /* width: 105px;
    height: 17px; */
    /* flex: none;
    order: 0;
    flex-grow: 0; */
}

.fileicon {
    width: 16px;
    height: 16px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.settingsNav {
    box-sizing: border-box;
    /* display: flex; */
    flex-direction: row;
    align-items: flex-start;
    padding: 24px;
    gap: 10px;
    /* position: relative; */
    /* width: 167px; */
    /* height: 100vh; */
    /* left: 392px; */
    /* top: 133px; */
    border-right: 1px solid #E8E8E8;
}

.settingsNavContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    /* width: 119px; */
    color: #8A8A8A;
}

.settingsNavContent p {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
}

.rubricDiv {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.rubricDivRow {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    padding: 0px;
    gap: 12px;
    /* width: 725px; */
    /* height: 65px; */
}

.rubricDivRowHeader {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
}

.starIcon {
    width: 16px;
    height: 16px;
}

.starDiv {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
}

.rubricInput {
    box-sizing: border-box;
    padding: 8px 16px;
    gap: 10px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #343434;
}

.inputBasic {
    box-sizing: border-box;
    padding: 8px 16px;
    height: 28px;
    background: #FFFFFF;
    border: 1px solid #ABABB5;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #343434;
}

.rubricInfo {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #2D3648;
}

.activityResourceDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 24px;
    /* position: absolute; */
    /* width: 900px; */
    /* height: 134px; */
    /* left: 416px; */
    /* top: 157px; */
}

.activityResearch {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 24px;
    /* width: 900px; */
    height: 28px;
}