.registerheader {
    position: absolute;
    width: 100%;
    height: 13.94px;
    left: 24px;
    top: 24px;
}

.registerblock {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding: 0px;
}

.registerback {
    width: 24px;
    height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.registermain {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
}

.registerscreen {
    display: flex;
    /* flex-direction: row; */
    align-items: flex-start;
    padding: 0px;
    gap: 45px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.profileinfo {
    width: 131px;
    height: 17px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #8C1515;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.heading {
    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 32px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.headingsign {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #343434;
}

.headingleft {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
}

.headingleft .span {
    color: #8C1515;
}

.profilediv {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 16px;
    width: 131px;
    height: 164px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.profilediv input[type=file] {
    opacity:0;
    width: 131px;
    height: 131px;
    border-radius: 10.4583px;
    position: absolute;
}

.profiledivFile {
    opacity:0;
    width: 131px;
    height: 131px;
    border-radius: 10.4583px;
    position: absolute;
}

.profileimg {
    width: 131px;
    height: 131px;
    border-radius: 10.4583px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.uploadinfo {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #8C1515;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.formelements input {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 10px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 5px;
}

.asterisk_input:after {
    content:" *";
    color: red;
}

.err {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #8C1515;
    display: flex;
}

label {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    color: #343434;
    flex: none;
    order: 0;
    flex-grow: 0;
}
