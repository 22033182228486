.loginHeader {
    position: absolute;
    width: 57px;
    height: 13.94px;
    left: 24px;
    top: 24px;
}

.logindiv {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    position: absolute;
    width: 516px;
    height: 313px;
    left: 462px;
    top: 117px;
}

.logindivider {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #8A8A8A;
    margin: 0 0 10px 0;
}

.back {
    width: 24px;
    height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.loginmain {
    display: flex;
    /* flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px; */
}

.logintitle {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #343434;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.loginmain input {
    box-sizing: border-box;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 10px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
}

.loginmain label {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.required:after {
    content:" *";
    color: red;
  }

.forgot {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    font-color: #888888;
    opacity: 0.5;
}
.forgot:hover {
    opacity: 1;
}

.lkeepme {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    width: 122px;
    height: 16px;
    margin-top: 10px;
    gap: 5px;
    flex: none;
    order: 3;
    flex-grow: 0;
}

#keepme {
    width: 12px;
    height: 12px;
    left: 1.38px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.lkeepmelabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #343434;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.errormsg {
    color: red;
}