.groupModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    gap: 18px;
    background: #F8F9FA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}

.groupModalCreator {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #FFFFFF;
}

.rateLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #434F69;
}

.rateButtonBold {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #2D3648;
}

.rateButtonNormal {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #2D3648;
}

.commentModalInput {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #2D3648;
    /* opacity: 0.6; */
}

.commentModalLabelInput {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #0066FF;
    opacity: 0.6;
}

.checkModalAnswer {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.checkModalButton {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
}

.confirmHeader {
    font-family: 'IBM Plex Sans';
    font-weight: 700;
    font-size: 16px;
}

.actionHeader {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
}

.actionBody {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #2D3648;
}

.deleteLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
}

.groupModalName {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #FFFFFF;
}

.groupModalLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #2D3648;
}

.groupModalInput {
    box-sizing: border-box;
    height: 32px;
    background: #FFFFFF;
    border: 2px solid #CBD2E0;
    border-radius: 6px;
}

.groupModalJoin {
    width: 145px;
    height: 32px;
    background: #CBD2E0;
    border-radius: 20px;
}

.groupModalCancel {
    width: 79px;
    height: 32px;
    border: 1px solid #CBD2E0;
    border-radius: 20px;
}

.groupModalTitle {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #2D3648;
}

.gotItButton {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
}

.normalStar {
    color: #CBD2E0;
}
  
.normalStar.active {
    color: #2D3648;
}

.normalStar.hover {
    color: blue;
}

.textareaStyle {
    resize: none;
    overflow: hidden;
    width: 100%; 
    box-sizing: border-box;
    font-size: 16px; 
    line-height: 1.4; 
};

.popoverLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #2D3648;
}

.customToastLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #565656;
}

.prompterInputLabels {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #2D3648;
}

.activitySettingLabels {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #2D3648;
}