.nav {
    /* box-sizing: border-box; */
    /* position: absolute; */
    position: fixed;
    width: 100%;
    height: 53px;
    left: 0px;
    top: 0px;
    margin: auto;
    background: #FFFFFF;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #E8E8E8;
}

.nav .profilePic {
    width: 16px;
    height: 16px;
    border-radius: 50%;
}

.nav .right {
    box-sizing: border-box;
    position: absolute;
    width: 177px;
    height: 53px;
    left: 1263px;
    top: 0px;
    border-left: 1px solid #E8E8E8;
} 

.nav .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    position: absolute;
    width: 141px;
    height: 17px;
    left: 18px;
    top: 18px;
} 

.navbrand {
    position: absolute;
    /* width: 57px; */
    /* height: 13.94px; */
    left: 35px;
    /* padding-left: 20px; */
    top: 19px;
    color: #8C1515;
}

.navuser {
    box-sizing: border-box;
    position: absolute;
    width: 192px;
    height: 53px;
    right: 0;
    top: 0px;
    border-left: 1px solid #E8E8E8;
}

.navuser p {
    position: absolute;
    width: 138px;
    height: 16px;
    left: 27px;
    top: 18px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #343434;
}

.groupMain {
    /* display: flex;
    flex-direction: column; */
    /* align-items: flex-start; */
    /* gap: 48px; */
    /* position: absolute;
    width: 100%; */
    /* height: 608px; */
    /* left: 40px; */
    top: 101px;
}

.groupRow1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 100%;
    /* height: 280px; */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.groupRowHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 100%;
    height: 46px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.groupRow2HeaderContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 24px;
    width: 100%;
    height: 30px;
}

.groupLabelLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    order: 1;
    flex-grow: 0;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #8C1515;
}

.groupLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #343434;
}

.grouprow2left {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    width: 334px;
    height: 28px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.groupinput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 6px 160px 6px 12px;
    gap: 10px;
    width: 228px;
    height: 28px;
    background: #F8F8F8;
    border-radius: 21px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.modalbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding: 0px 0px 16px; */
    gap: 10px;
    /* position: absolute; */
    /* width: 439px; */
    /* height: 157px; */
    background: #FFFFFF;
    box-shadow: 0px 4px 12px -1px rgba(138, 138, 138, 0.36);
    border-radius: 6px;
}

.modalboxcontent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.modalboxheader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F3F3F3;
    border-radius: 6px 6px 0px 0px;
}

.modalboxheaderleft {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.modalboxheaderight {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #8A8A8A;
}

.modalboxbody {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* padding: 0 30px; */
    gap: 24px;
    /* width: 407px; */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.modalboxbodyinput {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    height: 28px;
    color: #8A8A8A;
}

.modalname {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #343434;
}

.modalboxbodybutton {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    /* right: 30px;
    bottom: 30px; */
    /* width: 110px;
    height: 33px; */
    background: #8C1515;
    border-radius: 26px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #FFFFFF;
}

.joinpmodal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    /* position: absolute; */
    /* width: 439px;
    height: 176px; */
    background: #FFFFFF;
    box-shadow: 0px 4px 12px -1px rgba(138, 138, 138, 0.36);
    border-radius: 6px;
}

.joinpmodalcontent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    /* width: 439px;
    height: 160px; */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.joinpmodalheader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F3F3F3;
    border-radius: 6px 6px 0px 0px;
}

.joinpmodalheaderleft {
    /* position: absolute; */
    width: 16px;
    height: 16px;
    right: 20px;
    top: 13px;
}

.joinpmodalheaderight {
    /* position: absolute; */
    left: 16px;
    top: 12px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #8A8A8A;
}

.joinpmodalbody {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    /* width: 407px; */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.joinpmodalbodydiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    /* width: 407px; */
    /* height: 46px; */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.joinpmodalbodylabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.joinpmodalbodyinput {
    /* width: 407px; */
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    height: 28px;
    color: #8A8A8A;
}

.joinpfooter {
    box-sizing: border-box;
}

.joinpfooter .errormsg {
    left: 16px;
    position: absolute;
}

.joinpmodalsubmit {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* position: absolute; */
    /* right: 30px;
    margin-top: 10px; */
    padding: 8px 16px;
    background: #8C1515;
    border-radius: 26px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    /* line-height: 17px; */
    color: #FFFFFF;
}

.joinpmodal2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px -1px rgba(138, 138, 138, 0.36);
    border-radius: 6px;
}

.joinpmodalbody2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.joinpwords {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #8A8A8A;
}

.joinpmodalbodydiv2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.joinpmodalsubmit2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    background: #8C1515;
    border-radius: 26px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #FFFFFF;
}

.boldHeader {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #2D3648;
}

.inactiveHeader {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #717D96;
    opacity: 0.5;
}

.linear-bg {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    background-repeat: no-repeat;
    background-size: cover;
}

.groupDisplay {
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* padding: 0px;
    gap: 20px; */
    width: 100%;
    /* height: 210px;
    flex: none;
    order: 1;
    flex-grow: 0; */
}

.groupCard {
    /* display: flex;
    flex-direction: column; */
    /* align-items: flex-start;
    padding: 0px 0px 16px; */
    /* gap: 10px; */
    /* width: 305px;
    height: 210px; */
    background: #FFFFFF;
    border: 1px solid #F3F3F3;
    border-radius: 8px;
    /* flex: none;
    order: 0;
    flex-grow: 0; */
}

.groupCardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 16px;
    width: 305px;
    height: 194px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.groupImage {
    width: 305px;
    height: 140px;
    border-radius: 8px 8px 0px 0px;
    flex: none;
    order: 0;
    flex-grow: 0;
    object-fit: cover
}

.groupText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 16px;
    gap: 8px;
    flex: none;
}

.grouptextdiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.groupTitle {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #586CD7;
}
.groupAuthor {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #FFFFFF;
}
.groupTitleNew {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #FFFFFF;
}

.mainDivContainer {
    height: calc(100vh - 80px);
}

.createGroupLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #2D3648;
}

.inactiveDoneLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #2D3648;
    opacity: 0.5;
}

.createFormLabels {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #2D3648;
}

.createinfotext {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #717D96;
    opacity: 0.5;
}

.createFormInput {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #2D3648;
}

.memberTitle {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #9DA6B9;
}

.memberDescription {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #2D3648;
}

.mobileMediumLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
}

.noReportsLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #68758F;
}

.goBackLabel {
    font-family: 'IBM Plex Sans';
    /* font-style: normal; */
    /* font-weight: 600; */
    font-size: 16px;
    color: #FFFFFF;
}

.sidenavName {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 36px;
    color: #2D3648;
}

.sideNavLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 36px;
    color: #2D3648;
}

.groupRoleDiv {
    gap: 3px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 27px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #FFFFFF;
}
.addText {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #2D3648;
}

.inactiveFilterType {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #717D96;
}

.activeFilterType {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #2D3648;
}

.activeTab {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #2D3648;
}
.inactiveTab {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #717D96;
}
.filterText {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #2D3648;
}

.questionsMainLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #2D3648;
}

.questionReportsInfoLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    color: #2D3648;
    opacity: 0.5;
}

.reportAuthorLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #2D3648;
}

.reportActivityLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #2D3648;
    opacity: 0.6;
}

.reportQuestionLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #2D3648;
}

.reportActionsLabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #2D3648;
}

.createGroupBtn {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    border-radius: 27px;
}

.filterPopHeader {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: #2D3648;
}
.welcomeText {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #ABABB5;
}
.welcomeUser {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #2D3648;
}
.groupDescription {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
}

.groupRow2 {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    /* gap: 24px; */
    width: 100%;
    /* height: 280px; */
}

.groupRow2Header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
}

.groupRow2HeaderContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 30px;
    align-items: center;
    padding: 0px;
    gap: 24px;
}

.group2Label {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #343434;
}

.group2LabelLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 6px 160px 6px 12px;
    gap: 10px;
    background: #F8F8F8;
    border-radius: 21px;
    width: 228px;
    height: 28px;
}

.dlock {
    width: 13px;
    height: 13px;
}