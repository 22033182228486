.fnav {
    position: absolute;
    height: 13.94px;
    left: 24px;
    top: 24px;
    color: #8C1515;
}

.forgotdiv {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    position: absolute;
    width: 524px;
    height: 307px;
    left: 454px;
    top: 249px;
}
.forgotmain {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    /* width: 468px;
    height: 307px;
    flex: none;
    order: 1;
    flex-grow: 0; */
}

.flabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #343434;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.flabeldesc {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.fform {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 6px;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.rform {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.fformdiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    /* width: 468px;
    height: 65px; */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.rformdiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 6px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.filabel {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #343434;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.forgotInput {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    height: 40px;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    width: 100%;
}

.fimessage {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: green;
}

.fierror{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: red;
}

.fsubmit {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 10px;
    background: #8C1515;
    border-radius: 26px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #FFFFFF;
    flex: none;
    order: 2;
    flex-grow: 0;
}